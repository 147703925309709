
.cyber-content {
    margin: 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}

::v-deep .el-scrollbar {
    height: 100%;

    .el-scrollbar__wrap {
        overflow-x: hidden;

        .el-scrollbar__view {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
}

.customTable {
    flex: unset;
}

.middle-box {
    width: 100%;
    position: relative;
    margin-top: 40px;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        top: 46px;
        height: 1px;
        background: #F1F5FF;
    }

    .right {
        width: 90px;
        text-align: right;
        margin-top: -5px;
    }
}

.middle-tabs {

    ::v-deep {
        .el-tabs__nav {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .el-tabs__nav-wrap::after {
            content: none;
        }

        .el-tabs__header {
            margin-bottom: 0;
        }

        .el-tabs__active-bar {
            display: none;
        }

        .el-tabs__item {
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: normal;
            border-radius: 4px 4px 0 0;

            &.is-active {
                background: #E7F6EF;
                color: #666666;
            }
        }

        .el-tabs__item.is-top:nth-child(2) {
            padding-left: 20px;
        }

        .el-tabs__item.is-top:last-child {
            padding-right: 20px;
        }
    }
}

.bottom-box {
    margin: 30px 0;
    flex: 1;
    box-shadow: 0px 0px 9px 0px rgba(124, 124, 124, 0.11);
    border-radius: 4px;
    border-left: 3px solid #FFAC75;
}
